import React, { useEffect, useState } from 'react';
import { AvatarElement, NavContainer } from './Navigator.style';
import { featuresSchema } from './Navigator.schema';
import { AvatarComponent } from '../horarios/components/avatar.component';

interface NavigatorProps {
  // @TODO: add account type
  account: any;
  user: any;
}

const FEATURES_DEFAULT = ['dashboard', 'reviews', 'users', 'treinos', 'social', 'communication'];

export default function Navigator({account, user}: NavigatorProps) {
  const [activeItem, setActiveItem] = useState<string>("");

  const openSubmenu = (event: any, feature: string) => {
    event.preventDefault();
    if(feature !== activeItem) {
      setActiveItem(feature);
    } else {
      setActiveItem("");
    }
  }

  const pathName = window.location.pathname;

  useEffect(() => {
    Object.keys(featuresSchema).forEach((feature: string) => {
      if(featuresSchema[feature]?.items?.length > 0) {
        featuresSchema[feature]?.items?.forEach((item: any) => {
          if(`/${account.code}${item.url}` === pathName) {
            setActiveItem(feature);
          }
        })
      } else {
        if(`/${account.code}${featuresSchema[feature]?.url}` === pathName) {
          setActiveItem(feature);
        }
      }
    })
  },[]);

  const renderFilials = () => {
    if(account.features?.includes('filials')){
        return (
          <li 
          key={'filials'} 
          className={`nav-item ${'filials' === activeItem ? 'ativo' : ''} ${featuresSchema['filials']?.items?.length > 0 ? 'submenu' : ''}`}
        >
            <a 
            href={`${featuresSchema['filials']?.items?.length > 0 ? "#" : '/' + account.code + featuresSchema['filials']?.url}`}
            onClick={(event) => {
              if(featuresSchema['filials']?.items?.length > 0) {
                openSubmenu(event, 'filials');
              }
            }}
            >
              <i className={`fa-light fa-${featuresSchema['filials']?.icon}`}></i>
              <span>{featuresSchema['filials']?.label}</span>
            </a>
            {featuresSchema['filials']?.items?.length > 0 && (
              <ul className="submenu">
                {featuresSchema['filials']?.items?.map((item: any, index: number) => (
                  <li key={index} className={`${pathName === `/${account.code}${item.url}` ? 'ativo' : ''}`}>
                    <a 
                      href={`/${account.code}${item.url}`}
                    >
                    {item.label}
                    </a>
                  </li>
                ))}
              </ul>
            )}
        </li>
        )
    } else {
      return (
        <li 
            key={'filials'} 
            className={`nav-item ${'filials' === activeItem ? 'ativo' : ''}`}
          >
            <a 
            href={`/${account.code}/admin/filials/${account.firstFilial.code}/edit`}
            >
              <i className={`fa-light fa-${featuresSchema['filials']?.icon}`}></i>
              <span>Local de Atuação</span>
            </a>
              <ul className="submenu">
                <li className={`${pathName === `/${account.code}/admin/filials/${account.firstFilial.code}/edit` ? 'ativo' : ''}`}>
                  <a 
                    href={`/${account.code}/admin/filials/${account.firstFilial.code}/edit`}
                  >
                  Editar Endereço
                  </a>
                </li>
              </ul>
        </li>
      )
    }
  }

  const renderUsers = () => {
    if(account.accountType === 'personal'){
      return(
        <li 
          key={'users'}
          className={`nav-item ${'users' === activeItem ? 'ativo' : ''}`} 
        >
          <a 
            href={`/${account.code}/admin/alunos`}
          >
          <i className={`fa-light fa-${featuresSchema['users']?.icon}`}></i>
          <span>{featuresSchema['users']?.label}</span>
        </a>
      </li>
      )
    } else {
      return(
        <li 
          key={'users'} 
          className={`nav-item ${'users' === activeItem ? 'ativo' : ''} ${featuresSchema['users']?.items?.length > 0 ? 'submenu' : ''}`} 
        >
          <a 
            href={`${featuresSchema['users']?.items?.length > 0 ? "#" : '/' + account.code + featuresSchema['users']?.url}`}
            onClick={(event) => {
              if(featuresSchema['users']?.items?.length > 0) {
                openSubmenu(event, 'users');
              }
            }}
            >
              <i className={`fa-light fa-${featuresSchema['users']?.icon}`}></i>
              <span>{featuresSchema['users']?.label}</span>
            </a>
            {featuresSchema['users']?.items?.length > 0 && (
              <ul className="submenu">
                {featuresSchema['users']?.items?.map((item: any, index: number) => (
                  <li key={index} className={`${pathName === `/${account.code}${item.url}` ? 'ativo' : ''}`}>
                    <a 
                      href={`/${account.code}${item.url}`}
                    >
                    {item.label}
                    </a>
                  </li>
                ))}
              </ul>
            )}
      </li>
      )
    }
  }
  
  const {features} = account;
  let featuresFiltered = [...(features || []), 'finance', 'settings']
  
  if (!account.features?.includes('filials')){
    featuresFiltered.push('filials');
  }
  if (!account.features?.includes('users')){
    featuresFiltered.push('users');
  }

  // remover chaves que não existem no featuresSchema
  featuresFiltered = featuresFiltered.filter((feature: string) => featuresSchema[feature]);
  featuresFiltered = featuresFiltered.sort((a, b) => {
    return featuresSchema[a]?.order - featuresSchema[b]?.order
  });

  const toggleAtivoClass = (event: any) => {
    event.target.classList.toggle('ativo');
  }
  
  return (
    <NavContainer onClick={toggleAtivoClass}>
      <AvatarElement src={account?.avatar?.uri} alt={`${account.name} logo`} />
      <ul>
      <li className={`nav-item  submenu ${'profile' === activeItem ? 'ativo' : ''}`}>
        <a onClick={(event) => {openSubmenu(event, 'profile')}}>
          <div className="avatar-wrapper">
            <AvatarComponent aluno={user} noLink={true} hideName size={30} rounded/>
          </div>
          <span>{user.name}</span>
        </a>
        <ul className="submenu">
          <li>
            <a href={`/${account.code}/admin/alunos/${user.id}/edit`}>
              <span>Meu Perfil</span>
            </a>
          </li>
          <li>
            <a href={`/users/sign_out`} data-method="delete" data-confirm="Você deseja sair?" className="text-danger red-text">
              <span>Sair</span>
            </a>
          </li>
        </ul>
      </li>
      {featuresFiltered.map((feature: any, index: number) => {
        
        if(feature === 'filials'){
          return renderFilials();
        }

        if(feature === 'users'){
          return renderUsers();
        }

        if(!featuresSchema[feature]){
          return null;
        }


        return (
        // <NavItem key={feature.id} icon={feature.icon} label={feature.label} items={feature.items} />
        <li 
          key={index} 
          className={`nav-item ${feature === activeItem ? 'ativo' : ''} ${featuresSchema[feature]?.items?.length > 0 ? 'submenu' : ''}`}
        >
            <a 
            href={`${featuresSchema[feature]?.items?.length > 0 ? "#" : '/' + account.code + featuresSchema[feature]?.url}`}
            onClick={(event) => {
              if(featuresSchema[feature]?.items?.length > 0) {
                openSubmenu(event, feature);
              }
            }}
            >
              <i className={`fa-light fa-${featuresSchema[feature]?.icon}`}></i>
              <span>{featuresSchema[feature]?.label}</span>
            </a>
            {featuresSchema[feature]?.items?.length > 0 && (
              <ul className="submenu">
                {featuresSchema[feature]?.items?.map((item: any, index: number) => (
                  <li key={index} className={`${pathName === `/${account.code}${item.url}` ? 'ativo' : ''}`}>
                    <a 
                      href={`/${account.code}${item.url}`}
                    >
                    {item.label}
                    </a>
                  </li>
                ))}
              </ul>
            )}
        </li>
      )
      })}
      </ul>
    </NavContainer>
  )
}